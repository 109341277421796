import React from "react"
import  Layout from "../components/home-layout"
import SEO from "../components/seo"
import sitioPersonal from "../images/sitio-personal.svg"
import {Link} from "gatsby"
export default () => (
  <Layout>
    <SEO title="Sitio web personal"/>
    <h2>Bienvenidos a mi sitio web personal</h2>
   <section className="bloque1">
      <div className="ilustracion">
        <img src={sitioPersonal} alt="Sitio web personal"></img>
      </div>
      <div className="description">
      <p>
          En los últimos años me he especializado en la optimización de sitios
          web para darles mayor visibilidad en Google (o cualquier motor de
          búsqueda) y en el análisis de los datos del tráfico web.
        </p>
        <p>
          Sin embargo, también me he interesado fuertemente por la creación de
          páginas web y las nuevas oportunidades que se están desarrollando. 
          Uno de los principales puntos de bloqueo que solemos encontrar en la optimización de sitios web desarrollados con CMS tradicionales 
          suele ser la rapidez de carga de los contenidos. Buscando soluciones descubrí GatsbyJS y todo el mundo del JAMstack, NPM, Github, Netlify... 
          En mi tiempo libre estoy aprendiendo a desarrollar sitios con esta tecnología y esto es el resultado de mis experimentos.
        </p> 
        <h3><Link to={'/en-construccion/'}>Sitio web en construcción</Link></h3>
      </div>
    </section>
  </Layout>
)
